import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import './App.css';

// Lazy load the Dungeon component
const Dungeon = lazy(() => import('./Dungeon'));

function App() {
  // Function to generate a random dungeon ID
  const generateDungeonId = () => Math.random().toString(16).slice(2, 10);

  return (
    <Router>
      <div className="App">
        <nav>
          <Link to="/">Home</Link>
          <Link to="/dungeon">Dungeon</Link>
        </nav>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/dungeon/:id" element={<Dungeon />} />
            <Route path="/dungeon" element={<Navigate to={`/dungeon/${generateDungeonId()}`} />} />
            <Route path="/" element={<h1>Welcome to the Home Page!</h1>} />
          </Routes>
        </Suspense>
      </div>
    </Router>
  );
}

export default App;